<template>
  <v-container>
    <h1>Flha Assessment</h1>
    <template v-if="loaded">
      <v-list flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Work to be done By'"></v-list-item-title>
            <v-list-item-subtitle v-html="flha.workToBeDone"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Created By'"></v-list-item-title>
            <v-list-item-subtitle v-html="flha.createdBy.email"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Completed'"></v-list-item-title>
            <v-list-item-subtitle v-html="flha.completed"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Date'"></v-list-item-title>
            <v-list-item-subtitle v-html="flha.date"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Emergency Meeting Location'"></v-list-item-title>
            <v-list-item-subtitle v-html="flha.emergencyMeetingLocation"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Job Permit'"></v-list-item-title>
            <v-list-item-subtitle v-html="flha.jobPermit"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-simple-table>
        <thead>
          <th>Descriptions</th>
          <th>Hazard</th>
          <th>Plans</th>
        </thead>
        <tbody>
          <tr v-for="task in flha.tasksTable" :key="task.description">
            <td>{{task.description}}</td>
            <td>{{task.hazard}}</td>
            <td>{{task.plans}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-checkbox v-model="flha.requireGloves" :disabled=true label="Require Gloves" />
      <v-checkbox v-model="flha.ribbonNeeded" :disabled=true label="Ribbon Needed" />
      <v-checkbox v-model="flha.workingAlone" :disabled=true label="Working Alone" />
      <v-textarea v-model="flha.workingAloneReason" :disabled=true label="Reason"></v-textarea>
      <v-checkbox v-model="flha.permitsClosedOut" :disabled=true label="Permits closed out" />
      <v-checkbox v-model="flha.areaCleaned" :disabled=true label="Area cleaned" />
      <v-checkbox v-model="flha.hazardsRemaining" :disabled=true label="Hazards Remaining" />
      <v-textarea v-model="flha.hazardsRemainingReason" :disabled=true label="Reason"></v-textarea>
      <v-checkbox v-model="flha.injuries" :disabled=true label="Injuries" />
      <v-textarea v-model="flha.injuriesReason" :disabled=true label="Reason"></v-textarea>
      <v-list flat v-for="list in flha.checklist" :key="list.title">
        <v-subheader>{{list.title}}</v-subheader>
        <v-list-item v-for="item in list.list" :key="item.id">
          <v-list-item-content>
            <v-list-item-title v-text="item.label"></v-list-item-title>
            <v-list-item-subtitle>{{item.value ? 'Yes' : 'No'}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
    </template>
  </v-container>
</template>
<script>
import VIEW_FLHA from '@/queries/view_flha.gql';

export default {
  name: 'ViewFlha',
  props: ['id'],
  data() {
    return { loaded: false };
  },
  computed: {
  },
  apollo: {
    flha: {
      query: VIEW_FLHA,
      variables() {
        return { id: this.id };
      },
      update(data) {
        this.loaded = true;
        return data.me.relevantBusiness.flhas[0];
      }
    }
  }
};
</script>
