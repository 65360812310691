import Vue from 'vue';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { setContext } from '@apollo/client/link/context';
import localforage from 'localforage';

Vue.use(VueApollo);

const httpLink = new HttpLink({
  uri: process.env.NODE_ENV === 'production' ? 'https://app.thinksafetysolutions.com/graphql' : 'http://localhost:3000/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = window.localStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Authorization ${token}` : '',
    }
  };
});
// A usefull error handling tool provided by Apollo in order to execute some code when errors occur.
const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  // We log every GraphQL errors
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      if (message === 'Not Authorized') {
        window.localStorage.removeItem('authToken');
        window.location = '/';
      }
      return false;
    });
  }
  // When a 401 error occur, we log-out the user.
  if (networkError) {
    if (networkError.statusCode === 401) {
      window.localStorage.removeItem('authToken');
      window.location = '/';
    }
  }
});

const cache = new InMemoryCache();

persistCache({
  cache,
  storage: localforage,
});

const apolloClient = new ApolloClient({
  link: authLink.concat(onErrorLink).concat(httpLink),
  cache,
  resolvers: {
  }
});

export default new VueApollo({
  defaultClient: apolloClient,
});
