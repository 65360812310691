<template>
  <v-container>
    <template>
      <v-data-table
        dense
        :headers="headers"
        :items="events"
        :search="search"
        item-key="id">
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="view(item)"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </template>

  </v-container>
</template>
<script>
import FLHAS from '@/queries/flhas.gql';

export default {
  name: 'Flhas',
  data() {
    return {
      events: [],
      search: '',
      headers: [
        {
          text: 'Ref Num',
          align: 'start',
          value: 'id'
        },
        { text: 'Created By', value: 'createdBy.email' },
        { text: 'Started At', value: 'createdAt' },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  methods: {
    view(flha) {
      this.$router.push(`/flhas/${flha.id}`);
    }
  },
  apollo: {
    events: {
      query: FLHAS,
      update(data) {
        const events = data.me.relevantBusiness.flhas;
        return events;
      }
    }
  }
};
</script>
