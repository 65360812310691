import { render, staticRenderFns } from "./ViewFlha.vue?vue&type=template&id=abe0cfd8&"
import script from "./ViewFlha.vue?vue&type=script&lang=js&"
export * from "./ViewFlha.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VContainer,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSimpleTable,VSubheader,VTextarea})
