<template>
  <v-container>
    <h1>Create Flha</h1>
    <v-form v-model="valid">
      <v-text-field
        v-model="flha.workToBeDone"
        :rules="nameRules"
        :counter="10"
        label="Work To Be Done By"
        required
      ></v-text-field>
      <v-btn
        :disabled="!valid"
        @click="create"
      >
        submit
      </v-btn>
    </v-form>
  </v-container>
</template>
<script>
import CREATE_FLHA from '@/mutations/CreateFlha.gql';

export default {
  data() {
    return {
      valid: false,
      flha: {
        workToBeDone: null
      },
      nameRules: [
        (v) => !!v || 'Required'
      ]
    };
  },
  methods: {
    create() {
      console.log(this.flha);
      if (this.valid) {
        this.$apollo.mutate({
          mutation: CREATE_FLHA,
          variables: { input: { ...this.flha } }
        });
      }
    }
  }
};
</script>
