<template>
  <v-container>
    <template>
      <v-data-table
        dense
        :headers="headers"
        :items="policies"
        :search="search"
        item-key="id"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            autocomplete="off"
          />
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import POLICIES from '@/queries/policies.gql';

export default {
  name: 'Policies',
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Ref Num',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Uploaded by',
          value: 'uploadedBy.email'
        }
      ]
    };
  },
  apollo: {
    policies: {
      query: POLICIES,
      update(data) {
        return data.me.relevantBusiness.policies;
      }
    }
  }
};
</script>
