import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import apolloProvider from './graphql';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  apolloProvider,
  theme: {
    light: {
      primary: '#435EA2'
    }
  },
  render(h) {
    return h(App);
  },
}).$mount('#app');
