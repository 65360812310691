<template>
  <v-app>
    <v-app-bar
      app
      dark
      color="primary"
    >
      <v-app-bar-nav-icon v-if="loggedIn" @click="toggleMenu"></v-app-bar-nav-icon>
      <v-app-bar-title>Think Safety</v-app-bar-title>
      <v-spacer />
    </v-app-bar>
    <main-nav v-if="loggedIn"></main-nav>
    <v-main>
      <v-container fill-height>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import MainNav from '@/components/MainNav.vue';

export default {
  name: 'App',
  data() {
    return {
      loggedIn: false
    };
  },
  components: {
    MainNav
  },
  mounted() {
    const token = window.localStorage.getItem('authToken');
    if (token && token.length > 0) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  },
  methods: {
    toggleMenu() {
      this.$root.$emit('toggleMainMenu');
    }
  }
};
</script>
