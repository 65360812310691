<template>
  <v-navigation-drawer
   v-model="showMenu"
   fixed
   temporary
   height="100vh"
   width="200px"
   >

     <v-list >
       <v-list-item @click="navTo('/policies')">
         <v-list-item-icon >
           <v-icon>mdi-newspaper</v-icon>
         </v-list-item-icon>

         <v-list-item-content>
           <v-list-item-title>Policies</v-list-item-title>
         </v-list-item-content>

       </v-list-item>

       <v-list-item @click="navTo('/flhas')">
         <v-list-item-icon >
           <v-icon>mdi-map-outline</v-icon>
         </v-list-item-icon>

         <v-list-item-content>
           <v-list-item-title>Flhas</v-list-item-title>
         </v-list-item-content>

       </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block primary @click="logout">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>

export default {
  name: 'MainNav',
  data() {
    return {
      showMenu: false
    };
  },
  mounted() {
    this.$root.$on('toggleMainMenu', this.toggleMainMenu);
  },
  methods: {
    toggleMainMenu() {
      this.showMenu = !this.showMenu;
    },
    navTo(location) {
      this.$router.push({ path: location });
    },
    logout() {
      window.localStorage.removeItem('authToken');
      window.location.href = window.location.origin;
    }
  }
};
</script>
