<template>
  <v-flex>
    <v-alert v-if="hasError" type="error">
      {{this.errors[0]}}
    </v-alert>
    <v-form ref="form" v-if="!loggedIn">
      <v-text-field
        label="Email"
        type="email"
        v-model="email"
        :rules="emailRules"
        required
        autocomplete="username"
        prepend-inner-icon="mdi-at"
        outlined
        />
      <v-text-field
        label="Password"
        type="password"
        v-model="password"
        required
        :rules="passwordRules"
        autocomplete="current-password"
        outlined
        />
        <v-btn block @click="login">Login</v-btn>
    </v-form>
    <div class="center-menu" v-else>
      <router-link class="v-btn primary" to="/create_flha">Create FLHA</router-link>
      <router-link class="v-btn primary" to="/flhas">FLHA List</router-link>
    </div>
  </v-flex>
</template>

<script>
// @ is an alias to /src
import LOGIN from '@/mutations/Login.gql';

export default {
  name: 'Home',
  data: () => ({
    email: null,
    password: null,
    errors: [],
    loggedIn: false,
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      (v) => !!v || 'password is required'
    ]
  }),
  mounted() {
    const token = window.localStorage.getItem('authToken');
    console.log(token);
    if (token && token.length > 0) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  },
  computed: {
    hasError() {
      return this.errors && this.errors.length > 0;
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: LOGIN,
          variables: {
            input: {
              email: this.email,
              password: this.password
            }
          }
        }).then((response) => {
          const data = response.data.login;
          if (data.errors && data.errors.length > 0) {
            this.errors = data.errors;
          } else {
            window.localStorage.setItem('authToken', data.token);
            window.location.href = window.location.origin;
          }
        });
      }
    }
  }
};
</script>

<style scroped lang="scss">
  .center-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .v-btn.primary {
      padding: 1rem;
      max-width: 10rem;
      margin-bottom: 0.2rem;
    }
  }
</style>
