import Vue from 'vue';
import Vuetify, { VTextField } from 'vuetify/lib/framework';
import * as directives from 'vuetify/lib/directives';

// Not sure why VTextField has to be imported manually to support v-textarea

Vue.use(Vuetify, {
  components: {
    VTextField
  }
});

export default new Vuetify({
  directives
});
