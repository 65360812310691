import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Flhas from '../views/Flhas.vue';
import ViewFlha from '../views/ViewFlha.vue';
import Policies from '../views/Policies.vue';
import CreateFlha from '../views/CreateFlha.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/create_flha',
    name: 'CreateFlha',
    component: CreateFlha
  },
  {
    path: '/flhas',
    name: 'Flhas',
    component: Flhas,
  },
  {
    path: '/flhas/:id',
    props: true,
    name: 'ViewFlha',
    component: ViewFlha
  },
  {
    path: '/policies',
    name: 'Policies',
    component: Policies,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
